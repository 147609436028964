import { FunctionComponent, ReactNode } from 'react';
import { Background } from '@templafy/ui/components/background/Background';
import { FlexBox } from '@templafy/ui/components/flexBox/FlexBox';
import { TemplafyLogo } from '@templafy/ui/illustrations/TemplafyLogo';
import styles from './AuthenticationLayout.module.scss';

type AuthenticationLayoutProps = {
  children: ReactNode;
};

export const AuthenticationLayout: FunctionComponent<
  AuthenticationLayoutProps
> = ({ children }) => {
  return (
    <Background
      className={styles.background}
      width="100vw"
      height="100vh"
      alignItems="center"
      justifyContent="center"
      role="main"
    >
      {children}
    </Background>
  );
};

type AuthenticationBoxProps = {
  padding?: string | number;
  children: ReactNode;
};

export const AuthenticationBox: FunctionComponent<AuthenticationBoxProps> = ({
  children,
  padding = 24,
}) => {
  return (
    <FlexBox
      flexDirection="column"
      alignItems="center"
      width="100%"
      style={{ padding: 16 }}
    >
      <TemplafyLogo theme="dark" width={150} />
      <Background
        width="100%"
        maxWidth={480}
        maxHeight="80vh"
        flexDirection="column"
        color="white"
        style={{ padding, marginTop: 16 }}
        testId="authentication-box"
      >
        {children}
      </Background>
    </FlexBox>
  );
};
