import { FunctionComponent } from 'react';
import { useTranslator } from '@templafy/translation';
import { Button } from '@templafy/ui/components/button/Button';
import { Form } from '@templafy/ui/components/form/Form';
import { ManagedTextInput } from '@templafy/ui/reactHookForm/ManagedTextInput';
import {
  getManager,
  defaultFormProps,
  useZodDefaults,
} from '@templafy/ui/reactHookForm';
import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { VisuallyHidden } from '@templafy/ui/components/visuallyHidden/VisuallyHidden';
import {
  AuthenticationLayout,
  AuthenticationBox,
} from './layout/AuthenticationLayout';
import { submitForm } from '../utilities/submitForm';
import { useQueryParameters } from '@/hooks/useQueryParameters';

export const IdentificationPage: FunctionComponent = () => {
  const translator = useTranslator();
  const queryParameters = useQueryParameters();

  const redirectUri = queryParameters.get('redirect_uri') ?? undefined;
  const tenantId = queryParameters.get('tenantId') ?? undefined;
  const path = '/auth/identify';

  useZodDefaults(z);

  const schema = z.object({
    email: z.string().email().nonempty(),
  });

  type FormModel = z.infer<typeof schema>;

  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<FormModel>({
    ...defaultFormProps,
    resolver: zodResolver(schema),
  });

  const manage = getManager(control, schema);

  return (
    <AuthenticationLayout>
      <AuthenticationBox>
        <VisuallyHidden as="h1">{translator.logIn()}</VisuallyHidden>
        <Form
          id="identification-page-form"
          onSubmit={handleSubmit((data) => {
            submitForm(path, { ...data, redirectUri, tenantId });
          })}
          readOnly={isSubmitting}
          size="large"
        >
          <Form.Field testId="email-field">
            <Form.Label>{translator.emailFieldLabel()}</Form.Label>
            <ManagedTextInput
              {...manage('email')}
              type="email"
              autoComplete="email"
              name="email"
              autoFocus
              placeholder={translator.emailFieldPlaceholder()}
            />
            <Form.ValidationMessage>
              {errors.email?.message}
            </Form.ValidationMessage>
          </Form.Field>
          <Button
            type="submit"
            action="primary"
            loading={isSubmitting}
            testId="access-templafy"
            size="large"
          >
            {translator.loginPageAccessButtonText()}
          </Button>
        </Form>
      </AuthenticationBox>
    </AuthenticationLayout>
  );
};
