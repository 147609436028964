import { FunctionComponent, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Text } from '@templafy/ui/components/text/Text';
import { TextInput } from '@templafy/ui/components/textInput/TextInput';
import { PasswordInput } from '@templafy/ui/components/passwordInput/PasswordInput';
import { createShowFieldValidation } from '@templafy/ui/utilities/showFieldValidation';
import { useFormValidation } from '@templafy/validator';
import { Button } from '@templafy/ui/components/button/Button';
import { useTranslator } from '@templafy/translation';
import { IsDefined, IsString } from '@templafy/validator/decorators';
import { useQuery } from '@templafy/ui/hooks/dataFetchingHooks/useQuery';
import { IconLoading } from '@templafy/ui/icons/IconLoading';
import { Form } from '@templafy/ui/components/form/Form';
import {
  AuthenticationLayout,
  AuthenticationBox,
} from './layout/AuthenticationLayout';
import { Api } from '../generated/Api';
import { AcceptInviteDto, RedirectResponseDto } from '../generated/Contracts';
import { validatePassword } from '../utilities/passwordValidation';
import { useQueryParameters } from '@/hooks/useQueryParameters';

class UserActivationFormValues extends AcceptInviteDto {
  @IsDefined() @IsString() confirmPassword!: string;
}

export const AcceptInvitePage: FunctionComponent = () => {
  const translator = useTranslator();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { inviteId } = useParams();
  const queryParameters = useQueryParameters();
  const returnUrl = queryParameters.get('returnUrl') ?? '';

  const { state: verifyUserInviteState, fetch } = useQuery<
    { inviteId: string; returnUrl?: string },
    RedirectResponseDto,
    Error
  >({
    action: async ({ query }) => {
      const response = await Api.UserInvite.getInvite(
        query.inviteId,
        query.returnUrl,
      );

      if (response.location != null) {
        void navigate(response.location);
      }

      return response;
    },
  });

  const [formState, setFormValue, submit] =
    useFormValidation<UserActivationFormValues>(UserActivationFormValues, {
      values: { returnUrl },
      async onSubmit(validValues) {
        try {
          setIsLoading(true);
          const response = await Api.UserInvite.acceptInvite(
            inviteId ?? '',
            validValues,
          );
          window.location.replace(response.location);
          setIsLoading(false);
        } catch {
          setIsLoading(false);
        }
      },
      validation: (form, addValidation) => {
        if (form.password !== form.confirmPassword) {
          addValidation(
            'confirmPassword',
            translator.validationPasswordEqualsConfirmPassword(),
          );
        }

        // eslint-disable-next-line @typescript-eslint/no-base-to-string
        if (form.password && !validatePassword(form.password.toString())) {
          addValidation(
            'password',
            translator.validationPasswordRequirements(),
          );
        }
      },
    });

  const showFieldValidation = createShowFieldValidation({
    formState,
    setFormValue,
  });

  useEffect(() => {
    void fetch({ inviteId: inviteId ?? '', returnUrl });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inviteId, returnUrl]);

  const firstNameFieldValidation = showFieldValidation('firstName');
  const lastNameFieldValidation = showFieldValidation('lastName');
  const passwordFieldValidation = showFieldValidation('password');
  const confirmPasswordFieldValidation = showFieldValidation('confirmPassword');

  return (
    <AuthenticationLayout>
      {verifyUserInviteState.status !== 'loaded' ? (
        <IconLoading theme="inverted" size="large" />
      ) : (
        <AuthenticationBox>
          <Form id="accept-invite-page-form" size="large" onSubmit={submit}>
            <Text as="h1" styleAs="p">
              {translator.acceptInvitePageVerificationMessage()}
            </Text>
            <Form.Field testId="firstName-field">
              <Form.Label>
                {translator.acceptInvitePageFirstNameLabel()}
              </Form.Label>
              <TextInput
                autoFocus
                {...firstNameFieldValidation}
                placeholder={translator.acceptInvitePageFirstNamePlaceholder()}
              />
              <Form.ValidationMessage>
                {firstNameFieldValidation.message}
              </Form.ValidationMessage>
            </Form.Field>
            <Form.Field testId="lastName-field">
              <Form.Label>
                {translator.acceptInvitePageLastNameLabel()}
              </Form.Label>
              <TextInput
                {...lastNameFieldValidation}
                placeholder={translator.acceptInvitePageLastNamePlaceholder()}
              />
              <Form.ValidationMessage>
                {lastNameFieldValidation.message}
              </Form.ValidationMessage>
            </Form.Field>
            <Form.Field testId="password-field">
              <Form.Label
                tooltip={translator.acceptInvitePagePasswordTooltip()}
              >
                {translator.acceptInvitePagePasswordLabel()}
              </Form.Label>
              <PasswordInput
                {...passwordFieldValidation}
                placeholder={translator.acceptInvitePagePasswordPlaceholder()}
              />
              <Form.ValidationMessage>
                {passwordFieldValidation.message}
              </Form.ValidationMessage>
            </Form.Field>
            <Form.Field testId="confirmPassword-field">
              <Form.Label>
                {translator.acceptInvitePageConfirmPasswordLabel()}
              </Form.Label>
              <PasswordInput
                {...confirmPasswordFieldValidation}
                placeholder={translator.acceptInvitePageConfirmPasswordPlaceholder()}
              />
              <Form.ValidationMessage>
                {confirmPasswordFieldValidation.message}
              </Form.ValidationMessage>
            </Form.Field>
            <Button
              type="submit"
              action="primary"
              loading={isLoading}
              testId="finish-signup"
            >
              {translator.acceptInvitePageFinishSignupButtonText()}
            </Button>
          </Form>
        </AuthenticationBox>
      )}
    </AuthenticationLayout>
  );
};
