import { FunctionComponent } from 'react';
import { useTranslator } from '@templafy/translation';
import { Button } from '@templafy/ui/components/button/Button';
import { Link } from '@templafy/ui/components/link/Link';
import { Link as ReactRouterLink } from 'react-router-dom';
import { Form } from '@templafy/ui/components/form/Form';
import { z } from 'zod';
import {
  defaultFormProps,
  getManager,
  useZodDefaults,
} from '@templafy/ui/reactHookForm';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { ManagedTextInput } from '@templafy/ui/reactHookForm/ManagedTextInput';
import { ManagedPasswordInput } from '@templafy/ui/reactHookForm/ManagedPasswordInput';
import { Text } from '@templafy/ui/components/text/Text';
import { VisuallyHidden } from '@templafy/ui/components/visuallyHidden/VisuallyHidden';
import {
  AuthenticationLayout,
  AuthenticationBox,
} from './layout/AuthenticationLayout';
import { Api } from '../generated/Api';
import { useQueryParameters } from '@/hooks/useQueryParameters';

export const EmailLoginPage: FunctionComponent = () => {
  const queryParameters = useQueryParameters();
  const emailDefaultValue = queryParameters.get('email') ?? undefined;
  const tenantId = queryParameters.get('tenantId') ?? undefined;
  const redirectUri = queryParameters.get('redirect_uri') ?? undefined;
  const retryLoginParameters = new URLSearchParams(window.location.search);
  retryLoginParameters.delete('email');
  retryLoginParameters.delete('correlationId');
  const retryLoginUrl = `/?${retryLoginParameters.toString()}`;

  const translator = useTranslator();

  useZodDefaults(z);

  const schema = z.object({
    email: z.string().email().nonempty(),
    password: z.string().nonempty(),
  });

  type FormModel = z.infer<typeof schema>;

  const {
    watch,
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<FormModel>({
    defaultValues: {
      email: emailDefaultValue,
    },
    ...defaultFormProps,
    resolver: zodResolver(schema),
  });

  const manage = getManager(control, schema);

  const submit = handleSubmit(async (data) => {
    try {
      const response = await Api.EmailAuthentication.signIn({
        ...data,
        returnUrl: redirectUri,
        tenantId,
      });
      window.location.replace(response.location);
    } catch {
      return false;
    }
  });

  const isEmailValid =
    emailDefaultValue != null &&
    schema.pick({ email: true }).safeParse({ email: emailDefaultValue })
      .success;

  const email = watch('email');

  return (
    <AuthenticationLayout>
      <AuthenticationBox>
        <VisuallyHidden as="h1">{translator.logIn()}</VisuallyHidden>
        <Form
          id="email-login-page-form"
          onSubmit={submit}
          size="large"
          readOnly={isSubmitting}
        >
          <Form.Field testId="email-field">
            <Form.Label>{translator.emailFieldLabel()}</Form.Label>
            <ManagedTextInput
              {...manage('email')}
              placeholder={translator.emailFieldPlaceholder()}
              readOnly={isEmailValid || isSubmitting}
              autoFocus={!isEmailValid}
            />
            <Form.ValidationMessage>
              {errors.email?.message}
            </Form.ValidationMessage>
          </Form.Field>
          <Form.Field testId="password-field">
            <Form.Label>{translator.passwordFieldLabel()}</Form.Label>
            <ManagedPasswordInput
              {...manage('password')}
              placeholder={translator.passwordFieldPlaceholder()}
              autoFocus={isEmailValid}
            />
            <Form.ValidationMessage>
              {errors.password?.message}
            </Form.ValidationMessage>
          </Form.Field>
          <Link
            as={ReactRouterLink}
            testId="forgot-password"
            to={`/forgot-password?${new URLSearchParams({
              email,
              tenantId: tenantId ?? '',
              redirect_uri: redirectUri ?? '',
            }).toString()}`}
            style={{ alignSelf: 'flex-end' }}
          >
            {translator.loginPageForgotPasswordLabel()}
          </Link>
          <Button
            type="submit"
            action="primary"
            loading={isSubmitting}
            size="large"
            testId="access-templafy"
          >
            {translator.loginPageAccessButtonText()}
          </Button>
          <Text size="100" bold style={{ alignSelf: 'center', marginTop: 12 }}>
            <Link
              as={ReactRouterLink}
              to={retryLoginUrl}
              testId="change-account"
            >
              {translator.loginPagePasswordChangeAccount()}
            </Link>
          </Text>
        </Form>
      </AuthenticationBox>
    </AuthenticationLayout>
  );
};
